import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Carousel from 'react-multi-carousel';
import { calculateRating, roundNumber } from '../helper';
import Img from 'gatsby-image';
import 'react-multi-carousel/lib/styles.css';
import ArrowRight from '../../images/icon-arrow-right.inline.svg';
import '../../assets/styles/components/meet-the-team.scss';

const MeetTheTeam = () => {
  const data = useStaticQuery(graphql`
    query {
      allFeaturedPanelbeater {
        edges {
          node {
            aboutText
            companyName
            id
            name
            rating
            ratingCount
            state
            suburb
            url
            photoLink {
              local {
                childImageSharp {
                  fluid(maxWidth: 768) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const featured = data.allFeaturedPanelbeater.edges;

  const responsive = {
    desktop: {
      breakpoint: { max: 6000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1100, min: 767 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const showPagination = () => {
    return featured.length > 1;
  }

  return (
    <div className="meet-the-team-wrapper">
      <div className="container">
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          autoPlay={showPagination()}
          autoPlaySpeed={30000}
          centerMode={false}
          className=""
          dotListClass=""
          draggable={true}
          focusOnSelect={false}
          infinite
          keyBoardControl
          minimumTouchDrag={80}
          partialVisible
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={responsive}
          showDots={showPagination()}
          swipeable
        >
          {featured.map(({ node: feature }) => (
            <div className="team-slide" key={feature.id}>
              <div className="team-slide-image">
                {feature.photoLink.local && (
                  <Img fluid={feature.photoLink.local.childImageSharp.fluid} />
                )}
              </div>
              <div className="team-slide-text-wrapper">
                <div className="team-slide-team-member-wrapper">
                  <h3>{feature.name}</h3>
                  {feature.aboutText && (
                    <p>{feature.aboutText}</p>
                  )}
                </div>
                <div className="team-slide-shop-wrapper">
                  <h3>{feature.companyName}</h3>
                  <div className="review-wrapper">
                    {feature.suburb} {feature.state}
                    <span className="bullet">&bull;</span>
                    <div className="rating-stars-container">
                      <span className="rating-stars rating-stars-off">★★★★★</span>
                      <span className="rating-stars rating-stars-on" style={{ width: `${calculateRating(feature.rating)}%` }}>★★★★★</span>
                    </div>
                    {roundNumber(feature.rating, 1)} ({feature.ratingCount} Reviews)
                  </div>
                  <Link to={feature.url} className="arrow-cta">
                    View Shop Profile <ArrowRight />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default MeetTheTeam;
