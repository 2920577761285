import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import '../../assets/styles/components/zippay.scss';

const HomeCarousel = () => {
  const data = useStaticQuery(graphql`
    query {
      zipPayImage: file(relativePath: { eq: "zip-pay.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <div className="zippay-wrapper">
      <Link to="/zip">
        <Img fluid={data.zipPayImage.childImageSharp.fluid} alt="zip | repair it now, pay later" className="zippay-img" />
      </Link>
    </div>
  );
};

export default HomeCarousel;
