import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import '../../assets/styles/components/fastest-way.scss';

const FastestWay = () => {
  const data = useStaticQuery(graphql`
    query {
      stepOne: file(relativePath: { eq: "step1.png" }) {
        childImageSharp {
          fluid(maxWidth: 768) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      stepTwo: file(relativePath: { eq: "step2.png" }) {
        childImageSharp {
          fluid(maxWidth: 768) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      stepThree: file(relativePath: { eq: "step3.png" }) {
        childImageSharp {
          fluid(maxWidth: 768) {
            ...GatsbyImageSharpFluid
          }
        }
      }
	  dinggostats {
        averageTimeToQuote
      }
    }
  `);
	
  const stat = data.dinggostats;
	
  return (
    <div className="fastest-way-wrapper">
      <div className="container">
        <div className="intro">
          <h2>
            The fastest way to fix your car
          </h2>
          <p>
            Our average time to get back a personalised quote is currently {stat.averageTimeToQuote} hours. You can expect up to 3 quotes in 48 hours.
          </p>
        </div>
        <div className="fastest-way-row">
          <div className="fastest-way-row-image">
            <Img fluid={data.stepOne.childImageSharp.fluid} />
          </div>
          <div className="fastest-way-row-text-wrapper">
            <h3>Snap Photos</h3>
            <p>
              All you need is your mobile phone and license plate number. You can upload photos straight from your phone.
            </p>
          </div>
        </div>
        <div className="fastest-way-row">
          <div className="fastest-way-row-image">
            <Img fluid={data.stepTwo.childImageSharp.fluid} />
          </div>
          <div className="fastest-way-row-text-wrapper">
            <h3>Compare Quotes</h3>
            <p>
              Choose the best offer on price, time to repair, repairer ratings and more.
            </p>
          </div>
        </div>
        <div className="fastest-way-row">
          <div className="fastest-way-row-image">
            <Img fluid={data.stepThree.childImageSharp.fluid} />
          </div>
          <div className="fastest-way-row-text-wrapper">
            <h3>Repair Damage</h3>
            <p>
              Simply book in a time that works for you. Your chosen repairer will fix your car quickly, exactly as per your quote and with no hidden fees.
            </p>
          </div>
        </div>
        <div className="call-to-action">
          <h2>Ready to start?</h2>
          <Link to="/quote" className="btn">
            Get free quotes
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FastestWay;
