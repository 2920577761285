import React from 'react';
import { Link } from 'gatsby';
import '../../assets/styles/components/intro-cta.scss';

const AnnouncementBar = ({ title, intro, link, linkText }) => (
  <div className="intro-cta-wrapper">
    <div className="container">
      <h1
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      <p>{intro}</p>
      <Link to={link} className="btn">
        {linkText}
      </Link>
    </div>
  </div>
);

export default AnnouncementBar;
