import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Carousel from 'react-multi-carousel';
import { calculateRating, roundNumber } from '../helper';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/styles/components/customer-reviews.scss';

const CustomerReviews = () => {
  const data = useStaticQuery(graphql`
    query {
      allLatestReviews {
        edges {
          node {
            carMakeModel
            name
            rating
            reviewDate
            text
          }
        }
      }
      dinggostats {
        reviewAverage
        reviewCount
      }
    }
  `);
  const latestReviews = data.allLatestReviews.edges;
  const stat = data.dinggostats;

  const responsive = {
    desktop: {
      breakpoint: { max: 6000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 0,
    },
    tablet: {
      breakpoint: { max: 1100, min: 767 },
      items: 2,
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30,
    },
  };

  const showPagination = () => {
    if (typeof window !== 'undefined') {
      if (window.innerWidth > 1024) {
        return latestReviews.length > 3;
      } else {
        return latestReviews.length > 1;
      }
    }

    return true;
  }

  return (
    <div className="customer-reviews-wrapper">
      <div className="container">
        <div className="intro">
          <h2>
            How much do customers love DingGo?
          </h2>
          <div className="rating-stars-container">
            <span className="rating-stars rating-stars-off">★★★★★</span>
            <span className="rating-stars rating-stars-on" style={{ width: `${calculateRating(stat.reviewAverage)}%` }}>★★★★★</span>
          </div>
          <span className="review-total">{roundNumber(stat.reviewAverage, 2)} out of 5 kind of love.</span>
          <span className="based-off">Based on {stat.reviewCount}+ Customer Reviews</span>
        </div>
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          infinite={false}
          autoPlay={true}
          autoPlaySpeed={30000}
          centerMode={false}
          className=""
          dotListClass=""
          draggable={true}
          focusOnSelect={false}
          keyBoardControl
          minimumTouchDrag={80}
          partialVisible
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          responsive={responsive}
          showDots={showPagination()}
          swipeable
        >
          {latestReviews.length > 0 && (
            latestReviews.map(({ node }) => (
              <div className="customer-review-slide" key={node.name}>
                <div className="customer-review-slide-inner">
                  <div className="review">
                    {node.text}
                  </div>
                  <div className="rating-stars-container">
                    <span className="rating-stars rating-stars-off">★★★★★</span>
                    <span className="rating-stars rating-stars-on" style={{ width: `${calculateRating(node.rating)}%` }}>★★★★★</span>
                  </div>
                  <div className="review-author">
                    {node.name} - {node.reviewDate}
                  </div>
                  <div className="review-car">
                    {node.carMakeModel}
                  </div>
                </div>
              </div>
            ))
          )}
        </Carousel>
      </div>
    </div>
  );
};

export default CustomerReviews;
