import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { roundNumber, numberWithCommas } from '../helper';
import '../../assets/styles/components/love.scss';
import FastIcon from '../../images/fast-icon.inline.svg';
import LoveIcon from '../../images/love-icon.inline.svg';
import PriceIcon from '../../images/price-icon.inline.svg';
import VerifiedIcon from '../../images/varified-icon.inline.svg';

const Love = () => {
  const data = useStaticQuery(graphql`
    query {
      dinggostats {
        reviewAverage
        reviewCount
        panelbeaterCount
        quoteCount
        averageCostSaving
        averageTimeToQuote
      }
    }
  `);
  const stat = data.dinggostats;

  return (
    <div className="love-wrapper">
      <div className="container">
        <div className="row">
          <div className="col col-4 love-intro-wrapper">
            <h2>Why People Love DingGo</h2>
            <p>
              We are committed to providing the best possible experience, so you get maximum care at minimum cost.
            </p>
            <Link to="/quote" className="btn">
              Get free quotes
            </Link>
          </div>
          <div className="col col-8 love-points-wrapper">
            <div className="love-point">
              <PriceIcon className="love-point-img" />
              <div className="love-point-copy">
                <h3>The Best Repair Prices</h3>
                <p>DingGo customers save <span>{stat.averageCostSaving}%</span> per repair on average</p>
              </div>
            </div>
            <div className="love-point">
              <FastIcon className="love-point-img" />
              <div className="love-point-copy">
                <h3>Super Fast Repair Quotes</h3>
                <p>Average quote return time of <span>{stat.averageTimeToQuote} hours</span> with up to 3 in less than 48 hours.</p>
              </div>
            </div>
            <div className="love-point">
              <VerifiedIcon className="love-point-img" />
              <div className="love-point-copy">
                <h3>Verified Repairer Network</h3>
                <p>Over <span>{numberWithCommas(stat.panelbeaterCount)}+</span> DingGo verified repairers delivering over <span>{numberWithCommas(stat.quoteCount)}+</span> quotes.</p>
              </div>
            </div>
            <div className="love-point">
              <LoveIcon className="love-point-img" />
              <div className="love-point-copy">
                <h3>Customers Love DingGo</h3>
                <p>After receiving over <span>{numberWithCommas(stat.reviewCount)}</span> customers reviews, DingGo’s average customer rating is <span>{roundNumber(stat.reviewAverage, 1)}</span> out of 5.0.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Love;
