import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { numberWithCommas } from '../helper';
import { calculateRating } from '../helper';
import Img from 'gatsby-image';
import '../../assets/styles/components/network.scss';

const Network = () => {
  const data = useStaticQuery(graphql`
    query {
      allTop6Services {
        edges {
          node {
            seo_display
            pbCount
            ratingAvg
            ratingCount
            url
            id
            photoLink {
              local {
                childImageSharp {
                  fluid(maxWidth: 1920, maxHeight:1080) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
      dinggostats {
        panelbeaterCount
        quoteCount
      }
    }
  `);

  const service = data.allTop6Services.edges;
  const stat = data.dinggostats;

  return (
    <div className="network-wrapper">
      <div className="container">
        <div className="intro">
          <h2>
            A network of (only) the best repairers.
          </h2>
          <p>
            Our network of {stat.panelbeaterCount}+ repairers has provided over {numberWithCommas(stat.quoteCount)}+ quotes. We have got you covered for any of your car damage repair needs.
          </p>
        </div>
        <div className="network-tile-wrapper">
          {service.map(({ node: serv }) => (
            <a href={serv.url} className="network-tile" key={serv.url}>
              <div className="network-tile-image">
                {serv.photoLink && (
                  <Img fluid={serv.photoLink.local.childImageSharp.fluid} />
                )}
              </div>
              <div className="network-tile-text-wrapper">
                <h3>{serv.seo_display}</h3>
                {serv.ratingCount >= 20 && (
                <div className="review-wrapper">
                  <div className="rating-stars-container">
                    <span className="rating-stars rating-stars-off">★★★★★</span>
                    <span className="rating-stars rating-stars-on" style={{ width: `${calculateRating(serv.ratingAvg)}%` }}>★★★★★</span>
                  </div>
                  ({serv.ratingCount}+ ratings)
                </div>
                )}
                {serv.pbCount >= 5 && (
                <p>
                  {serv.pbCount}+ Verified Shops
                </p>
                )}
              </div>
            </a>
          ))}
        </div>
        <div className="call-to-action">
          <a href="/panel-beating-services" className="btn btn-outline">
            View all services
          </a>
        </div>
      </div>
    </div>
  );
};

export default Network;
