import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Carousel from 'react-multi-carousel';
import Img from 'gatsby-image';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/styles/components/carousel.scss';

const HomeCarousel = () => {
  const data = useStaticQuery(graphql`
    query {
      allBannerimages {
        edges {
          node {
            photoLink {
              local {
                childImageSharp {
                  fluid(maxWidth: 768, quality: 75) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const images = data.allBannerimages.edges;

  const responsive = {
    desktop: {
      breakpoint: { max: 6000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 100,
    },
    tablet: {
      breakpoint: { max: 1100, min: 767 },
      items: 2,
      partialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30,
    },
  };

  // return null;
  return (
    <div className="home-carousel-wrapper">
      <Carousel
        additionalTransfrom={0}
        arrows={false}
        autoPlay={true}
        autoPlaySpeed={3000}
        centerMode={false}
        className=""
        dotListClass=""
        draggable={false}
        focusOnSelect={false}
        infinite
        keyBoardControl
        minimumTouchDrag={80}
        partialVisible
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={responsive}
        showDots={false}
        swipeable={true}
      >
        {images && (
          images.length > 0 && (
            images.map(({ node: image }) => {
              if (image.photoLink.local) {
                return (
                  <div className="slide" key={image.photoLink.local.childImageSharp.fluid}>
                    <Img fluid={image.photoLink.local.childImageSharp.fluid} className="slide-img" />
                  </div>
                );
              }
              return null;
            })
          )
        )}
      </Carousel>
    </div>
  );
};

export default HomeCarousel;
